<template>
  <div class="auth-wrapper bg-light-info auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="appLogoFull"
            height="70"
          />
        </b-link>

        <b-card-title class="mb-3 text-center">
          {{ $t('login.forgotPassword') }} 🔒
        </b-card-title>
        <b-card-text class="mb-1 text-info text-center">
          {{ $t('login.requiredText_1') }}
          <em class="font-weight-bolder text-danger">{{ emailOTPSent }}</em>
          {{ $t('login.requiredText_1_2') }}
        </b-card-text>
        <b-card-text class="mb-3 text-info text-center">
          {{ $t('login.requiredText_2') }}
          <em class="font-weight-bolder text-danger">{{ emailOTPSent }}</em>
          {{ $t('login.requiredText_2_2') }}
        </b-card-text>
        <!-- <b-card-text class="mb-1 text-info text-center">
          {{ $t('login.requiredText_3') }} <b-link class="text-warning">
            {{ $t('login.requiredText_4') }}
          </b-link>
        </b-card-text> -->

        <validation-observer
          ref="refFormObserver"
          #default="{ invalid }"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- OTP -->
            <b-form-group
              :label="$t(('login.otpCode'))"
              label-for="otp-code"
            >
              <validation-provider
                #default="{ errors }"
                name="OTP"
                rules="required|alphaNum"
              >
                <b-form-input
                  id="otp-code"
                  v-model="otpCode"
                  :state="errors.length > 0 ? false:null"
                  name="otp-code"
                  maxlength="4"
                  :placeholder="$t('login.otpCodePlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
            >
              {{ $t('login.resetPassword') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t('login.backToLogin') }}
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import {
  BCard, BImg,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, onUnmounted, computed, onMounted,
} from '@vue/composition-api'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'
import { required, alphaNum } from '@validations'
import { $themeConfig } from '@themeConfig'

import authStoreModule from './authStoreModule'

export default {
  components: {
    BCard,
    BImg,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appLogoFull: defaultLogo } = $themeConfig.app
    const appLogoFull = computed(() => store.getters['app/getLogo']).value || defaultLogo
    const AUTH_APP_STORE_MODULE_NAME = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTH_APP_STORE_MODULE_NAME, authStoreModule)
    }
    const { router } = useRouter()
    const username = computed(() => store.getters['app-auth/getUsername'])

    onMounted(() => {
      if (!username.value) {
        router.go(-1)
      }
    })

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(AUTH_APP_STORE_MODULE_NAME)) {
      //   store.unregisterModule(AUTH_APP_STORE_MODULE_NAME)
      // }
    })
    const otpCode = ref(null)

    // eslint-disable-next-line no-unused-vars
    const { toastSuccess, toastError } = useToast()
    const { refFormObserver } = formValidation()
    function validationForm() {
      this.$bvModal.show('modal-api-loading')
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          store.dispatch('app-auth/validateOtp', { code: otpCode.value, username: username.value })
            .then(() => {
              toastSuccess({
                title: 'messagesList.success',
                content: 'messagesList.auth.otpSuccess',
              })
              router.push({ name: 'auth-forgot-password-reset' })
            })
            .catch(error => {
              toastError({
                title: 'messagesList.error',
                content: this.$t(`messagesList.auth.${error?.response?.data?.message || error.message || error}`),
              })
            })
            .finally(() => {
              this.$bvModal.hide('modal-api-loading')
            })
        }
      })
    }
    const emailOTPSent = computed(() => store.getters['app-auth/getEmail'])

    return {
      otpCode,
      emailOTPSent,
      // validations
      required,
      alphaNum,

      refFormObserver,

      // method
      validationForm,
      appLogoFull,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
